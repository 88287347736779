import * as React from "react";

import CountUp from "react-countup";
import { Container } from "react-bootstrap";
import { Button, Typography } from "@mui/material";

import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { ReactComponent as Spock } from '../assets/svg/spock.svg';
import { ReactComponent as Consulting } from '../assets/svg/consulting.svg';


import Schedule from "./schedule";
import { styles } from "../styles";
import { COLORS, THEME } from "../constants";
import { FormField } from "../forms/fields";
import { sendIntakeEmail } from "../api/email";
import { getAllEvents, scheduleIntake } from "../api/spock";
import { calendarEventType, intakeMeetingType } from "../types";
import { useCustomState, getTimeString, validateEmail } from "../utils";

function Intake({...props}) {
    const {style, className, loading, notification, logoStyle, contentStyle, scheduleMargin, userInfoMargin, ...rest} = props;

    const [i, setI] = React.useState<number>(0);
    const [slot, setSlot] = React.useState<[Date, Date]>();
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const [scheduledEvents, setScheduledEvents] = React.useState<calendarEventType[]>([]);
    const [intakeMeeting, setIntakeMeeting] = useCustomState<intakeMeetingType>({projectDescription: "N/A"});

    React.useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            const initializeEventsWrapper = async () => {
                const response = await (await getAllEvents())?.json()
                if (response) {
                    setScheduledEvents(response.events?.map((event: any) => ({start: event.start, end: event.end})))
                }
            }
            initializeEventsWrapper()
        }
    }, [initialized])


    const schedule = async () => {
        if (!slot || !intakeMeeting) return; 

        const [start, end] = slot;
        loading?.setLoading({message: "Scheduling meeting...", load: true})
        const status = await scheduleIntake({...intakeMeeting, startTime: start, endTime: end})
        
        console.log("[Landing][schedule] (status) >>", status);
        const response = await sendIntakeEmail(intakeMeeting.userEmail, start.toDateString(), getTimeString(start), getTimeString(end))
        console.log("[Landing][schedule] (response) >>", response);
        loading?.setLoading({message: null, load: false})

        if (status && response) {
            notification?.setNotification({message: "Intake scheduled!", notify: true})
        }
    }

    const validateUserInfo = () => {
        if (!intakeMeeting.userName) {
            setIntakeMeeting({userNameMessage: "Invalid name"})
            return;
        } else if (!validateEmail(intakeMeeting.userEmail)) {
            setIntakeMeeting({userNameMessage: "", userEmailMessage: "Invalid email"})
            return;
        } else if (!intakeMeeting.projectTitle) {
            setIntakeMeeting({userNameMessage: "", userEmailMessage: "", projectTitleMessage: "Invalid startup name"})
            return;
        } else  {
            setIntakeMeeting({userNameMessage: "", userEmailMessage: "", projectTitleMessage: ""})
            setI(1)
        }
    }

    return (
        <div className="flex column align-center" style={{...style}}>
            <span className="flex column align-center" style={{...logoStyle}}>
                <Logo fill={THEME.BACKGROUND_ACCENT} style={{margin: "0 0 15px 0", height: 40}}/>
                <Spock fill={THEME.BACKGROUND_ACCENT} style={{margin: "0 0 10px 0"}}/>
                <Consulting fill={THEME.BACKGROUND_ACCENT}/>
                <Typography style={{color: THEME.HEADER_ACCENT, fontWeight: "bolder", fontSize: THEME.FONT.SUB_HEADING, marginTop: 10}}>Where ideas become reality.</Typography>
            </span>
            <div className="flex column align-center" style={{margin: i === 1? scheduleMargin : userInfoMargin, ...contentStyle}}>
                {i === 0?
                    <>
                    <Typography color={THEME.BACKGROUND_ACCENT} fontWeight={"bolder"} fontSize={20}>Let's chat!</Typography>
                    <FormField 
                        variant="outlined" 
                        placeholder="Name"
                        style={{...styles.formField()}} 
                        value={intakeMeeting.userName || ""}
                        helperText={intakeMeeting.userNameMessage}
                        inputStyle={{border: `1px solid ${COLORS.WHITE}`, height: 40}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setIntakeMeeting({userName: event.target.value})}}
                    /> 
                    <FormField 
                        variant="outlined" 
                        placeholder="Email"
                        style={{...styles.formField()}} 
                        value={intakeMeeting.userEmail || ""}
                        helperText={intakeMeeting.userEmailMessage}
                        inputStyle={{border: `1px solid ${COLORS.WHITE}`, height: 40}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setIntakeMeeting({userEmail: event.target.value})}}
                    /> 
                    <FormField 
                        variant="outlined" 
                        placeholder="Startup Name"
                        style={{...styles.formField()}} 
                        value={intakeMeeting.projectTitle || ""}
                        helperText={intakeMeeting.projectTitleMessage}
                        inputStyle={{border: `1px solid ${COLORS.WHITE}`, height: 40}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setIntakeMeeting({projectTitle: event.target.value})}}
                    /> 
                    
                    <Button sx={{...styles.button(), marginTop: "30px"}} onClick={validateUserInfo}>Schedule Intake</Button>
                    </>
                    : <></>
                }
                {i == 1?
                    <>
                    <Schedule 
                        events={scheduledEvents}
                        onClickSlot={([start, end]: [Date, Date]) => {setSlot([start, end])}}
                    />
                    <Button sx={{...styles.button(), marginTop: "30px"}} onClick={schedule}>Schedule Intake</Button>
                    </>
                    : <></>
                }
            </div>
        </div>
    )
}

export default Intake;
