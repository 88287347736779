import { API } from "../constants";
import { post, get } from "../utils";
import { intakeMeetingType } from "../types";

export async function scheduleIntake(intakeMeeting: intakeMeetingType) {
    // const url = "http://127.0.0.1:8001/api/intake/scheduleIntake"; // development url
    const url = "http:/spockcons.com/api/spock/intake/scheduleIntake";

    try {
        return await post(url, JSON.stringify(intakeMeeting), {'Content-Type': API.APPLICATION_JSON})
    } catch (e) {
        console.error("[spock][scheduleIntake] >> Failed to schedule intake")
    }
}

export async function getAllEvents() {
    // const url = "http://127.0.0.1:8001/api/intake/getSpockCalendar"; // development url
    const url = "http:/spockcons.com/api/spock/intake/getSpockCalendar";

    try {
        return await get(url, undefined, {'Content-Type': API.APPLICATION_JSON})
    } catch (e) {
        console.error("[spock][scheduleIntake] >> Failed to schedule intake")
    }
}