import * as React from "react";

import { Button, Typography } from "@mui/material";

import watermark from "../assets/media/watermark.png"

import "../assets/utils.css";
import { resize } from "../utils";
import { styles } from "../styles";
import { THEME } from "../constants";
import Intake from "../components/intake";
import Overview from "../components/overview";
import Solutions from "../components/solutions";
import { LoadingContext, NotificationContext } from "..";
import { Loading, Notification, useScreenOrientation, useViewport } from "../support";

type sectionType = "overview" | "featured" | "solutions" | "intake"
function Landing({...props}) {
    const loading = React.useContext(LoadingContext);
    const notification = React.useContext(NotificationContext);

    const {width} = useViewport();
    const orientation = useScreenOrientation();

    const [selectedSection, setSelectedSection] = React.useState<sectionType>("overview");

    React.useEffect(() => {
        if (resize(width, orientation)) {
            setSelectedSection("overview")
        }
    }, [width, orientation])

    return (
        <>
            <div className={`width-100 flex column height-100 align-center`} style={{background: `url("${watermark}")`, backgroundSize: "900px", backgroundRepeat: "no-repeat"}}>
                {/** TODO: Add collapsible navbar on the left side **/}  
                <div className="flex align-center align-self-start" style={{margin: "25px 0 50px 25px"}}>
                    <Button sx={{...styles.sectionButton(selectedSection === "overview")}} onClick={() => setSelectedSection("overview")}>Overview</Button>
                    <Button sx={{...styles.sectionButton(selectedSection === "solutions")}} onClick={() => setSelectedSection("solutions")}>Solutions</Button>
                    {resize(width, orientation)? <></> : <Button sx={{...styles.sectionButton(selectedSection === "intake")}} onClick={() => setSelectedSection("intake")}>Contact</Button>}
                    {/* <Button sx={{...styles.sectionButton(selectedSection === "featured")}} onClick={() => setSelectedSection("featured")}>Featured Projects</Button> */}
                </div>
                {resize(width, orientation) || selectedSection === "intake"? <div style={{width: resize(width, orientation)? "50%" : "100%", backgroundColor: THEME.DOMINANT, zIndex: -1, right: 0}} className="fixed height-100"></div> : <></>}
                <div className="flex width-100">
                    {/* <div style={{width: resize(width, orientation)? "50%" : "100%"}} className="flex align-center justify-center column"> */}
                    {selectedSection === "overview"? <Overview style={{width: resize(width, orientation)? "50%" : "100%"}}/> : <></>}
                    {selectedSection === "solutions"? <Solutions style={{width: resize(width, orientation)? "50%" : "100%"}}/> : <></>}
                    {/* </div> */}
                    {resize(width, orientation) || selectedSection === "intake"? 
                        <>
                            <Intake 
                                loading={loading} 
                                notification={notification} 
                                userInfoMargin={"25px 0 0 0"}
                                scheduleMargin={"25px 0 0 0"}
                                contentStyle={{height: "calc(100% - 175px - 161px)"}}
                                style={{width: resize(width, orientation)? "50%" : "100%"}} 
                            /> 
                        </>
                        : <></>
                    }
                </div>

            </div>
            <Loading loading={loading?.loading} setLoading={loading?.setLoading}/>
            <Notification notification={notification?.notification} setNotification={notification?.setNotification} sx={{backgroundColor: THEME.BACKGROUND_ACCENT, color: THEME.DOMINANT}}/>
        </>
    )
}

export default Landing;
