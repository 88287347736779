import * as React from "react";
import { API, REGEX } from "./constants";
import { calendarEventType, dateType, timeType } from "./types";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export function nullify(obj: any) {
    return JSON.parse(JSON.stringify(obj))
}

export function useCustomState<T>(initialState: any): [T, CallableFunction] {
    const [state, setState] = React.useState(initialState);
    const setCustomSate = (newState: any) => {
        setState((prevState: any) => ({...prevState, ...newState}))
    };
    
    return [state, setCustomSate];
}

export async function post(url: RequestInfo | URL, body: any, headers: HeadersInit, other?: any) {
    return await fetch(url, {
        method: API.POST,
        headers: {
            // 'Content-Type': API.APPLICATION_JSON,
            ...headers
        },
        credentials: 'same-origin',
        body: body,
        ...other
    })
}

export async function get(url: string, args?: Object, headers?: HeadersInit) {
    if (args) {
        url += "?";
        for (const [arg, value] of Object.entries(args)) {
            url += `${arg}=${value}&`
        }
    }

    return await fetch(url, {
        method: API.GET,
        headers: {
            'Content-Type': API.APPLICATION_JSON,
            ...headers
        },
    })
}

export function validateEmail(email: string | undefined): boolean {
    if (email === undefined) return false;
    return email.toLowerCase().match(REGEX.EMAIL) !== null;
}

export function validatePassword(password: string | undefined): boolean {
    if (password === undefined) return false;
    return password.length >= 6;
}

export function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function validateUrl(url: string) {
    return /^https?:\/\/([\w-]+\.)+\w{2,}(\/.+)?$/.test(url);
}

export function addToLocalStorage(key: string, value: string) {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        clearLocalStorage();
        localStorage.setItem(key, value);
    }
}

export function clearLocalStorage() {
    const permanent = ["sybSchedule", "nowPlaying", "currentLocationId"];
    const n = localStorage.length;

    for (let i = 0; i < n; i++) {
        const key = localStorage.key(i);
        if (!key) continue;
        if (permanent.includes(key)) continue;
        localStorage.removeItem(key);
    }
}

/**
 * Wraps functions to collapse / un-collapse on resizing of window 
 * 
 * @param callback function expected to be run
 * @param setCollapse hook to handle changing collapse state 
 * @param width of the current window
 * @param orientation portrait or landscape
 * @returns a callback to first execute the callback then un-=-collapse
 */
export function dynamicResizeCallbackWrapper(callback: CallableFunction, setCollapse: CallableFunction, width?: number, orientation?: string) {
    return (args: any) => {
        callback(args);
        if (!resize(width, orientation)) setCollapse(true)
    }
}

/***
 * Checks if website should be dynamically resized to width > widthBreakpoint
 * @returns true if width > widthBreakpoint and orientation is landscape else false
 */
export function resize(width: number | undefined, orientation: string | undefined): boolean {
    if (width && orientation) return width > 1000 && (isMobile? orientation.includes("landscape") : true);
    return false;
}

export function getValidSlots(events: calendarEventType[], date: Date): [Date, Date][] {
    const slots: [Date, Date][] = []
    
    const takenSlots: [Date, Date][] = []
    for (const event of events) {
        const start = new Date(event.start.dateTime)
        const end = new Date(event.end.dateTime)

        if (!checkDatesMatch(start, date)) continue;
        else takenSlots.push([start, end])
    }

    for (let i = 600; i < 1080; i += 60) {
        const [year, month, day] = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
        const dateString = `${year}-${month < 10? "0" + month : month}-${day < 10? "0" + day : day}`;

        let n = Math.floor(i / 60);
        let m = i - n*60;
        const start = new Date(`${dateString}T${n < 10? "0" + n : n}:${m < 10? "0" + m : m}:00`)

        n = Math.floor((i + 40) / 60);
        m = i + 40 - n*60
        const end = new Date(`${dateString}T${n < 10? "0" + n : n}:${m < 10? "0" + m : m}:00`)
        
        if (checkTimeTaken(takenSlots, start, end)) continue
        slots.push([start, end])
    }

    // console.log("[getValidSlots] (date, slots) >>", date, slots)
    return slots;
}

export function getTimeString(date: Date) {
    let hour = date.getHours()
    
    const noonIndicator = hour > 12? "pm" : "am"
    hour = hour > 12? hour - 12 : hour;

    const min = date.getMinutes()

    return `${hour > 9? hour : "0" + hour}:${min > 10? min : "0" + min} ${noonIndicator}`
}

/*** Helper Functions ***/

export function checkDatesMatch(date1: Date, date2: Date): boolean {
    if ((date1.getDate() !== date2.getDate()) || (date1.getMonth() !== date2.getMonth()) || (date1.getFullYear() !== date2.getFullYear())) return false;
    return true;
}

function checkTimeTaken(slots: [Date, Date][], start: Date, end: Date): boolean {
    for (const slot of slots) {
        const [slotStart, slotEnd] = slot;
        if (dateRangeOverlaps(slotStart, slotEnd, start, end)) return true
    }
    return false;
}

function dateRangeOverlaps(a_start: Date, a_end: Date, b_start: Date, b_end: Date): boolean {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
    if (b_start <  a_start && a_end   <  b_end) return true; // a in b
    return false;
}