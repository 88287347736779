import * as React from "react";

import { Container } from "react-bootstrap";
import { Button, IconButton, Typography } from "@mui/material";

import DrawIcon from '@mui/icons-material/Draw';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TerminalIcon from '@mui/icons-material/Terminal';
import HandymanIcon from '@mui/icons-material/Handyman';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

import { THEME } from "../constants";

function Solutions({...props}) {
    const {style, className, ...rest} = props;

    return (
        <div style={{...props.style}} className={`width-100 flex justify-around align-center`}>
            <div className={`flex justify-center align-center column`}>
                <Solution Icon={HandymanIcon} title="Hardware prototyping" description=""/>
                <Solution Icon={ArchitectureIcon} title="CAD design" description=""/>
                <Solution Icon={DeveloperBoardIcon} title="Firmware integration" description=""/>
            </div>
            <div className={`flex justify-center align-center column`}>
                <Solution Icon={TerminalIcon} title="Software development" description=""/>
                <Solution Icon={DrawIcon} title="Wireframe design" description=""/>
                <Solution Icon={SmartToyIcon} title="AI / ML integration" description=""/>
            </div>
        </div>
    )
}

export default Solutions;


function Solution({...props}) {
    const {Icon, title, description, ...rest} = props;
    
    return (
        <div className="flex align-center column" style={{margin: "20px 0 20px 0"}}>
            <IconButton disabled>
                <Icon style={{width: 100, height: 100, color: THEME.BUTTON_ACCENT}}/>
            </IconButton>
            <Typography style={{color: THEME.BUTTON_ACCENT}}>{title}</Typography>
            <Typography style={{color: THEME.DOMINANT}}>{description}</Typography>
        </div>
    )
}
