export const API = {
    GET: "get",
    POST: "post",
    DELETE: "delete",
    APPLICATION_JSON: "application/json",
}

export const COLORS = {
    WHITE: "#FFFFFF",
    TRANSPARENT: "transparent",
}

export const THEME = {
    TEXT: "#F4F4F4",
    DOMINANT: "#557373",
    HEADER_ACCENT: "#ECB159",
    ACTIVE_ACCENT: "#ECB159",
    BUTTON_ACCENT: "#272401",
    BACKGROUND_ACCENT: "#F2EFEA",

    FONT: {
        TITLE: 26,
        HEADING: 22,
        SUB_HEADING: 20,
        PARAGRAPH: 12
    }
}

export const REGEX = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export const CONTACT = {
    DOMAIN: "https://akatary.com/api",

    FROM_EMAIL: "spockcons@gmail.com",
    FROM_EMAIL_CONFIRM: "spockcons@gmail.com",

    ZOOM_LINK: "https://us05web.zoom.us/j/5280157922?pwd=3CiIVsoRJ2nOyxvPwjAa3FdoQTxhCB.1"
}
