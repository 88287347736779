import * as React from "react";

import CountUp from "react-countup";
import { Container } from "react-bootstrap";
import { Button, Typography } from "@mui/material";

import { THEME } from "../constants";

function Overview({...props}) {
    const {style, className, ...rest} = props;

    return (
        <div style={{...style}} className={`flex column justify-center align-center column`}>
            <CountUp start={0} end={14} delay={0}>
                {({ countUpRef }) => (
                    <div className="flex column align-center">
                        <span ref={countUpRef} style={{fontSize: 180, color: THEME.DOMINANT}}/>
                        <Typography color={THEME.DOMINANT} fontSize={THEME.FONT.HEADING} style={{marginTop: "-30px", maxWidth: "80%", textAlign: "center"}}>
                            <b>Software</b> Projects Delivered
                        </Typography>
                    </div>
                )}
            </CountUp>

            <CountUp start={0} end={8} delay={0}>
                {({ countUpRef }) => (
                    <div className="flex column align-center" style={{margin: "50px 0 0 0"}}>
                        <span ref={countUpRef} style={{fontSize: 180}}/>
                        <Typography color={THEME.BUTTON_ACCENT} fontSize={THEME.FONT.HEADING} style={{marginTop: "-30px", maxWidth: "80%", textAlign: "center"}}>
                            <b>Hardware</b> Projects Delivered
                        </Typography>
                    </div>
                )}
            </CountUp>
        </div>
    )
}

export default Overview;
