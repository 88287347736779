import * as React from "react";

import { Container } from "react-bootstrap";
import { Button, Typography } from "@mui/material";

import "../assets/utils.css";
import { THEME } from "../constants";
import 'react-calendar/dist/Calendar.css';
import Calendar from "react-calendar";
import { checkDatesMatch, getTimeString, getValidSlots } from "../utils";
import { styles } from "../styles";

function Schedule({...props}) {
    const {style, className, onClickSlot, events, ...rest} = props;

    const [slots, setSlots] = React.useState<[Date, Date][]>([]);
    const [selectedSlot, setSelectedSlot] = React.useState<number | null>(null);
    const [selectedDay, setSelectedDay] = React.useState<Date>(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));

    React.useEffect(() => {
        if (selectedDay >= new Date()) {
            setSlots(getValidSlots(events, selectedDay))
        }
    }, [selectedDay])

    React.useEffect(() => {
        if (slots.length) setSelectedSlot(0);
    }, [slots])

    React.useEffect(() => {
        if (selectedSlot === null) return;
        else if (slots.length > selectedSlot) onClickSlot(slots[selectedSlot])
    }, [selectedSlot])

    return (
        <div style={{...props.style}} className={`flex column justify-center align-center`}>
            <Typography color={THEME.BACKGROUND_ACCENT} fontWeight={"bolder"} fontSize={20}>Select a date</Typography>
            <Calendar 
                value={selectedDay}
                showFixedNumberOfWeeks
                className={"calendar"}
                tileClassName={"calendar-tile"}
                onClickDay={(value: Date, event: any) => setSelectedDay(value)}
                tileDisabled={({ activeStartDate, date, view }) => date < new Date()}
            />

            {slots.length?
                <div className="flex align-center column" style={{marginTop: 20}} >
                    <Typography color={THEME.BACKGROUND_ACCENT} fontWeight={"bolder"} fontSize={20}>Select a slot</Typography>
                    <div className="flex scroll no-scrollbar" style={{width: "min(90vw, 500px)", height: 80, backgroundColor: THEME.BACKGROUND_ACCENT, border: `1px solid ${THEME.DOMINANT}`}}>
                        {slots?.map((slot, i) => {
                            const selected = i === selectedSlot;
                            const nextSelected = i + 1 === selectedSlot;

                            const [start, end] = slot;
                            const nextSlot = slots[i + 1]
                            if (i % 2 === 1) return <span key={`slot-${Math.random()}`}></span>

                            return (
                                <div className="flex column align-center justify-center" key={`slot-${Math.random()}`}>
                                    <Button sx={{...styles.slotButton(selected)}} onClick={() => setSelectedSlot(i)}>
                                        {getTimeString(start)} - {getTimeString(end)}
                                    </Button>
                                    {nextSlot? 
                                        <Button sx={{...styles.slotButton(nextSelected)}} onClick={() => setSelectedSlot(i + 1)}>
                                            {getTimeString(nextSlot[0])} - {getTimeString(nextSlot[1])}
                                        </Button>
                                        :
                                        <></>
                                    }
                                </div>
                            )
                        })}
                    </div> 
                </div>: <></>
            }
        </div>
    )
}

export default Schedule;
