import { CONTACT } from "../constants";
import { get } from "../utils";

export async function sendIntakeEmail(email: string, day: string, start: string, end: string) {
    const url = `${CONTACT.DOMAIN}/mail/contact`;

    const body = {
        name: "",
        email: email,
        subject: "Spock Intake Meeting",
        message: JSON.stringify(`Hey!\nThank you for booking an intake meeting.\nHere is your meeting info:\nDate: ${day}\nTime: ${start} - ${end}\nlink: ${CONTACT.ZOOM_LINK}\n\n- Best,\n Spock Cons`), 
        fromEmail: CONTACT.FROM_EMAIL,
        fromEmailConfirm: CONTACT.FROM_EMAIL_CONFIRM,
        reciepientEmails: [CONTACT.FROM_EMAIL, email],
    }
    
    return await get(url, body, {});
}