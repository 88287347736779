import { stylesType } from "./types";
import { COLORS, THEME } from "./constants";

export const styles: stylesType = {
    button: () => ({
        width: 250,
        height: 40,
        borderRadius: 0,
        boxShadow: "none",
        margin: `7.5px 0 0 0`,
        textTransform: "none",
        color: THEME.BACKGROUND_ACCENT,
        backgroundColor: THEME.BUTTON_ACCENT,
        
        '&:hover': {
            color: THEME.BACKGROUND_ACCENT,
            backgroundColor: THEME.ACTIVE_ACCENT
        },
    }),

    formField: () => ({
        width: 250,
        margin: 7.5,
        borderRadius: 0,
        fontSize: THEME.FONT.PARAGRAPH,
        '&:active': {
            border: `1px solid ${COLORS.WHITE}`
        }
    }),

    slotButton: (selectedSlot?: boolean) => ({
        height: 40,
        minWidth: 200,
        borderRadius: 0,
        textTransform: "none",
        color: selectedSlot? THEME.BACKGROUND_ACCENT : THEME.DOMINANT,
        backgroundColor: selectedSlot? THEME.BUTTON_ACCENT : COLORS.TRANSPARENT,

        "&:hover": {
            color: THEME.BACKGROUND_ACCENT,
            backgroundColor: THEME.ACTIVE_ACCENT
        }
    }),

    sectionButton: (selected: boolean) => ({
        textTransform: "none", 
        fontWeight: selected? "bold" : "",
        color: selected? THEME.ACTIVE_ACCENT : THEME.BUTTON_ACCENT, 
    })
}